import React, { useEffect, useState } from 'react';
import { useStyles } from './ProfileContent.style';
import firebase from '../../../utils/firebase';
import Grid from '@material-ui/core/Grid';
import ProductCard from '../../ShoeCards/ProductCard';
import TabPanel from './TabPanel';

const ProfileContent = (props) => {
  const [products, setProducts] = useState([]);
  const { tab, profileUserId } = props;
  const [noProducts, setNoProducts] = useState(false);
  const classes = useStyles();

  const fetchProducts = async () => {
    const productsQuery = await firebase
    .firestore()
    .collection('users')
    .doc(profileUserId)
    .collection('products')
    .orderBy('currentTime', 'desc')
    .get();

    const productsDoc = productsQuery.docs;
    if (productsDoc.length > 0) {
      const productsIds = productsDoc.map((item) => item.id);
      getProductsDetails(productsIds, products => setProducts(products));
    } else {
      setNoProducts(true);
    }
  }

  const getProductsDetails = (productIds, callback) => {
    let productRequests = productIds.map(productId => {
      return firebase
      .firestore()
      .collection('products')
      .doc(productId)
      .get()
    });
    Promise.all(productRequests)
    .then(docs => {
      let items = docs.map(doc => {
        let product = doc.data()
        product['productId'] = doc.id
        return product
      });
      callback(items);
    })
    .catch(error => console.log(error))
  };

  useEffect(() => {
    fetchProducts();
  }, []);


  return (
    <div className={classes.contentContainer}>
      <TabPanel value={tab} index={0} elevation={0}>
        {noProducts
          ? <div className={classes.noProducts}> No products listed </div>
          : <Grid
            container
            spacing={2}
          >
            {products.map((product, i) => {
              return (
                <Grid
                  item
                  xs={4}
                  md={3}
                  key={i}
                >
                  <ProductCard hit={product} />
                </Grid>
              );
            })}
          </Grid>
        }
      </TabPanel>
    </div>
  );
};

export default ProfileContent;

