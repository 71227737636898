import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import algoliaClient from './utils/algolia/algoliaClient';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import './styles/App.css';

import Profile from './pages/Profile/Profile';
import About from './pages/About';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home';
import TopAppBar from './components/TopBar/TopAppBar';
import BottomBar from './components/BottomBar/BottomBar';
import Product from './pages/Product';

function App() {
  const classes = useStyles();

  return (
    <div style={{ height: '100%', flexGrow: 1, background: 'black' }}>
      <InstantSearch
        indexName={'Users_Shoes'} //PRODUCTION: Users_Shoes, DEVELOPMENT: "Users_Shoes_DEV"}
        searchClient={algoliaClient}
      >
        <Configure hitsPerPage={3} />
        <TopAppBar />
      </InstantSearch>

      {/* creates margin for top bar */}
      <div className={classes.toolbar}></div>

      <Switch>
        <Route
          exact
          path='/profile/:nickname'
          render={(props) => (
            <React.Fragment>
              <Profile key={props.match.params.nickname} {...props} />
              <BottomBar />
            </React.Fragment>
          )}
        ></Route>
         <Route exact path='/product/:productID'>
          <Product />
          <BottomBar />
        </Route>
        <Route exact path='/'>
          <Home />
          <BottomBar />
        </Route>
        <Route path='/'>
          <Redirect to='/' />
        </Route>
        <Route exact path='/about'>
          <About />
        </Route>
        <Route exact path='/contact'>
          <Contact />
        </Route>
      </Switch>
    </div>
  );
}

export default App;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      height: theme.appbarHeight.smDown,
    },
    [theme.breakpoints.up('md')]: {
      height: theme.appbarHeight.mdUp,
    },
  },
}));
