import React, { useEffect, useState } from 'react';
import { useStyles } from './ProductInfo.style';
import firebaseGetUserProfile from '../../../utils/firebaseGetUserProfile';
import { useHistory } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';

const ProductInfo = ({ product }) => {
  const [userProfile] = useState({});
  const [avatarSource, setAvatarSource] = useState('');
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const {
    images,
    name,
    nickname,
    price,
    size,
    userId,
    description,
    condition,
    createdAt,
    likesCount,
    viewCount,
  } = product;

  const daysSincePosted = (createdAt) => {
    let date1 = Date.now() / 1000;
    let date2 = createdAt;
    let diff = date1 - date2;

    let days = Math.round(diff / 3600 / 24);
    return days;
  };

  const imageCarousel = (images) => {
    const productImages = images.map((image) => {
      return (
        <img
          alt='product'
          className={`${classes.image} ${loaded ? classes.imgLoaded : ''}`}
          key={image}
          src={image}
        />
      );
    });
    return productImages;
  };

  const getSource = async () => {
    const userProfile = await firebaseGetUserProfile(userId);
    return userProfile.profileImg;
  };

  const handleMessageClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleAvatarClick = async () => {
    if (!userId) return;
    const user = await firebaseGetUserProfile(userId);
    history.push(`/profile/${user?.nickname || ''}`, { user: user || '' });
  };

  useEffect(() => {
    (async () => {
      try {
        let s = await getSource();
        setAvatarSource(s);
      } catch (e) {
        console.error('avatar image was not loaded');
      }
    })();
  }, []);

  return (
    <div className={classes.productContainer}>
      {/** PRODUCT IMAGES */}
      <div className={classes.imageContainer}>
        <Carousel emulateTouch={true} showThumbs={false} showStatus={false}>
          {imageCarousel(images)}
        </Carousel>
      </div>

      {/** PRODUCT && SELLER DETAILS */}
      <div className={classes.informationContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Typography variant='h2' style={{ marginTop: 3 }}>
            {name}
          </Typography>
        </div>

        <Typography style={{ fontWeight: 'bold', marginTop: 4 }} variant='h2'>
          ${price}
        </Typography>
        <Typography variant='body1' style={{ color: '#828282', marginTop: 8 }}>
          Posted {daysSincePosted(createdAt.seconds)} ago
        </Typography>

        <Typography variant='body2' style={{ color: '#828282', marginTop: 4 }}>
          {viewCount} views · {likesCount} likes
        </Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 16,
          }}
        >
          <LayersOutlinedIcon
            style={{ height: 24, color: '#828282', marginRight: 8 }}
          />

          <Typography variant='body2' style={{ color: '#828282' }}>
            Size {size}
          </Typography>

          <CheckCircleOutlineOutlinedIcon
            style={{
              height: 24,
              color: '#828282',
              marginRight: 8,
              marginLeft: 16,
            }}
          />
          <Typography variant='body2' style={{ color: '#828282' }}>
            {condition}
          </Typography>
        </div>

        <Button
          variant='contained'
          className={classes.button}
          onClick={() =>
            handleMessageClick(
              'https://apps.apple.com/us/app/sneakerlist-buy-sell-shoes/id1536068234'
            )
          }
        >
          <Typography style={{ fontWeight: 'bold' }} variant='body1'>
            Message on the Hubble
          </Typography>
        </Button>

        {/* Description */}
        <Typography>
          {`${
            description && description.trim().length
              ? description
              : 'No description'
          }`}
        </Typography>

        {/** Seller Information Card  */}
        <div
          style={{
            marginTop: 16,
            alignSelf: 'flex-start',
            display: 'flex',
            alignItems: 'center',
            maxHeight: '48px',
          }}
        >
          {/* Avatar Image */}
          <div
            className={`${classes.avatarContainer} ${
              size ? classes[size] : ''
            }`}
          >
            <img
              loading='lazy'
              onClick={handleAvatarClick}
              onLoad={() => setLoaded(true)}
              className={`${classes.avatarImg} ${
                loaded ? classes.imgLoaded : ''
              }`}
              src={avatarSource}
              alt='userProfileImage'
            />
          </div>
          <div
            style={{
              display: 'flex',
              paddingLeft: '8px',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant='body1'
              onClick={handleAvatarClick}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              {`${nickname}`}
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '2px',
                alignItems: 'center',
              }}
            >
              <Rating
                name='size-small'
                size='small'
                readOnly
                value={userProfile?.averagerating}
              />
              <Typography
                variant='subtitle1'
                style={{ color: '#828282', marginLeft: 8 }}
              >
                {`(${userProfile?.reviewcount || '0'})`}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
