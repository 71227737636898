import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingTop: 80
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerSm: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  imageCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  mainImage: {
    height:'90%',
    width: '90%',
    objectFit: 'cover',
    marginBottom: 34,
    paddingLeft: 50
  },
  mainImageSm: {
    height: '70%',
    width: '70%',
    objectFit: 'cover',
    marginBottom: 34,
    paddingRight : 40
  },
  mainText: {
    fontWeight: 'bold',
    fontSize: 42,
    color: '#000000',
    paddingTop: 150,
    paddingBottom: 20,
    lineHeight: '50px',
  },
  mainTextSm: {
    fontWeight: 'bold',
    fontSize: 24,
    color: '#000000',
    paddingLeft : 30,
    paddingRight : 30,
  },
  subText: {
    fontSize: 18,
    color: '#000000',
  },
  subTextSm: {
    paddingTop: 30,
    paddingBottom: 30,
    fontSize: 14,
    color: '#000000',
    paddingLeft : 20,
    paddingRight : 20,
  },
  bottomMargin: {
    marginBottom: 30
  },
  row: {
    content: '',
    display: 'table',
    clear: 'both',
  },
  column: {
    float: 'left',
    width: '50%',
    padding: '100px',
  },
  columnSm: {
    width: '100%',
  }
}));