import React from 'react';
import { useStyles } from './PriceSection.style';
import { useTheme } from '@material-ui/core/styles';
import {
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import price_section_image from '../../../assets/price_section.png';

const PriceSection = () => {
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={matchesSmUp ? classes.column : classes.columnSm}>
          <div className={matchesSmUp ? classes.center : classes.centerSm}>
            <Typography className={matchesSmUp ? classes.mainText : classes.mainTextSm}>
            Monitor real-time market prices across major resale platforms
            </Typography>
          </div>
          <div className={matchesSmUp ? classes.center : classes.centerSm}>
            <Typography className={matchesSmUp ? classes.subText : classes.subTextSm}>
            Get real-time market prices for your items. Compare prices without having to visit each platform separately
            </Typography>
          </div>
        </div>
        <div className={matchesSmUp ? classes.column : classes.columnSm}>
          <div className={classes.imageCenter}>
            <img
              className={matchesSmUp ? classes.mainImage : classes.mainImageSm}
              src={price_section_image}
              alt={'price comparison for resellable sneakers'}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default PriceSection;