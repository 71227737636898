import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: 700,
    background: 'white',
    justifyContent: 'center',
    padding: 10
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1142px',
    display: 'flex',
    marginTop : 50
  },
}));