import React, { useEffect, useState } from 'react';
import { useStyles } from './Profile.style';
import {
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import firebase from '../../utils/firebase';
import firebaseGetUserProfile from '../../utils/firebaseGetUserProfile';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress'
import ProfileHeader from '../../components/Profile/ProfileHeader/ProfileHeader';
import ProfileTabs from '../../components/Profile/ProfileTabs/ProfileTabs';
import ProfileContent from '../../components/Profile/ProfileContent/ProfileContent';

const Profile = (props) => {
  const classes = useStyles();
  const { userId } = props;
  const [profileUserData, setProfileUserData] = useState({});
  const [profileUserId, setProfileUserId] = useState({});
  const { nickname } = useParams();
  const [loading, setLoading] = useState(0); // 0 = loading, 1 = no user, 2 = user found
  const [tab, setTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const getProfileFromNickname = async () => {
    const searchUserDoc = await firebase
      .firestore()
      .collection('users')
      .where('nickname', '==', nickname)
      .limit(1)
      .get();

    if (searchUserDoc.docs.length > 0) {
      const profileUserIdTemp = searchUserDoc.docs[0].id
      const userDataTemp = await firebaseGetUserProfile(profileUserIdTemp);
      userDataTemp['userId'] = profileUserIdTemp;
      setProfileUserData(userDataTemp);
      setProfileUserId(profileUserIdTemp);

      setLoading(2);
    } else {
      setLoading(1);
    }
  };

  useEffect(() => {
    getProfileFromNickname();
    window.scrollTo(0, 0)
  }, []);

  return (
    <React.Fragment>
      <div className={classes.body}>
        <div className={classes.container}>
          {loading === 2
            /* user found */
          ? <React.Fragment>
            <ProfileHeader
              profileUserData={profileUserData}
              userId={userId}
            />
            <ProfileTabs tab={tab} handleChangeTab={handleChangeTab}/>
            <ProfileContent tab={tab} profileUserId={profileUserId} />
          </React.Fragment>
          : loading === 1
            /* no user found */
            ? <div className={classes.notExist}>
              <WarningIcon style={{ fontSize: 100, display: 'flex' }} />
              <Typography> {`User with nickname "${nickname}" does not exist`} </Typography>
            </div>
            // progress circle
            : <div className={classes.loading}>
              <CircularProgress/>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;