import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'black',
    alignItems: 'center',
    paddingRight: '4%',
    paddingLeft: 'calc(4% - 10px)',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '2%'
    },
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1)',
  },
  toolbar: {
    width: '100%',
    maxWidth: '1142px',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      height: theme.appbarHeight.mdUp,
    },
    [theme.breakpoints.up('md')]: {
      height: theme.appbarHeight.mdUp,
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.appbarHeight.smDown,
      minHeight: 48,
      width: '100%'
    },
  },
  logoContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  logo: {
    cursor: 'pointer',
    maxHeight: '50px',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      width: '100px',
    },
    [theme.breakpoints.up('md')]: {
      width: '160px',
    },
  },
  menuContainer: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
    justifyContent: 'right'
  },
}));