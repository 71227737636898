import React, { useState } from 'react';
import { useStyles } from './ProductCard.style';
import { Card, CardMedia, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const ProductCard = (props) => {
  const { hit } = props;
  const classes = useStyles();
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);

  const goToProduct = () => {
    if (hit.objectID){
      history.push(`/product/${hit.objectID}`, { product: hit });
    } else {
      history.push(`/product/${hit.productId}`, { product: hit });
    }
  };

  return (
    <Card
      className={classes.root}
      style={{ height: '100%', aspectRatio: 1}}
      onClick={() => goToProduct()}
    >
      <CardMedia
        onLoad={() => setLoaded(true)}
        className={`${classes.img} ${loaded ? classes.loaded : ''}`}
        component='img'
        src={hit.images[0]}
      />
      <Typography
        className={classes.text}
        variant='subtitle1'
        style={{ color: 'white', }}
      >
        ${hit.price}
      </Typography>
    </Card>
  );
};

export default ProductCard;