import React, { useEffect } from 'react';
import { useStyles } from './About.style';
import {
  Container,
  Typography,
  Grid,
} from '@material-ui/core';
import MobileScreenOne from '../../assets/about_ss/ss_1.png';
import MobileScreenTwo from '../../assets/about_ss/ss_2.png';
import MobileScreenThree from '../../assets/about_ss/ss_3.png';

export default function About() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMobileAppClick = () => {
    const newWindow = window.open(
      'https://apps.apple.com/us/app/sneakerlist-buy-sell-shoes/id1536068234',
      '_blank',
      'noopener, noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Container className={classes.root} maxWidth='xl' disableGutters>
      <div className={classes.toolbar} />
      <Container maxWidth='md'>
        <Grid style={{ flex: 1, height: '100%' }} container direction='column'>
          <Grid style={{ marginBottom: '2em' }} item>
            <Typography variant='h3'>{'About'}</Typography>
          </Grid>
          <Grid className={classes.section} container alignItems='center'>
            <Typography variant='h6'>
              <Typography
                style={{
                  fontFamily: 'urw-din',
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                }}
                display='inline'
                variant='h4'
              >
                HUBBLE
              </Typography>
              <span>&nbsp;</span> is a peer-to-peer sneaker marketplace platform
              that allows you to buy and sell the hottest kicks with other
              verified resellers and sneaker fans. Find buyers and sellers near
              you and deal sneakers smoothly without paying hefty shipping fees
              and commissions.
            </Typography>
          </Grid>
          <Grid container direction='row'>
            <Grid
              style={{ cursor: 'pointer' }}
              onClick={handleMobileAppClick}
              xs={4}
              item
            >
              <img
                alt='hubble mobile app screenshot 1'
                width='100%'
                heigh='auto'
                src={MobileScreenOne}
              />
            </Grid>
            <Grid
              style={{ cursor: 'pointer' }}
              onClick={handleMobileAppClick}
              xs={4}
              item
            >
              <img
                alt='hubble mobile app screenshot 2'
                width='100%'
                heigh='auto'
                src={MobileScreenTwo}
              />
            </Grid>
            <Grid
              style={{ cursor: 'pointer' }}
              onClick={handleMobileAppClick}
              xs={4}
              item
            >
              <img
                alt='hubble mobile app screenshot 3'
                width='100%'
                heigh='auto'
                src={MobileScreenThree}
              />
            </Grid>
          </Grid>

          <Grid
            className={classes.section}
            alignContent='stretch'
            container
            alignItems='center'
          >
            <Typography variant='h6'>
              <Typography display='inline' variant='h5'>
                LIST
              </Typography>
              <span>&nbsp;</span> your shoes for sale and let other members
              discover your items more easily. Hubble is solely dedicated
              for sneakers, so your products will get higher quality exposure
              than other generic marketplaces and social media platforms. Your
              items will also be viewed by buyers near you which will make it
              easier to schedule in-person meetups or coordinate shipping.
            </Typography>
          </Grid>
          <Grid
            className={classes.section}
            alignContent='stretch'
            container
            alignItems='center'
          >
            <Typography variant='h6'>
              <Typography display='inline' variant='h5'>
                FIND
              </Typography>
              <span>&nbsp;</span> the best sneaker deals from sellers near your
              location. Search for Air Jordans, Yeezys or any other kicks that
              you are searching, filtering by size, price and location. If you
              find a pair that you want to buy, simply send a message to the
              seller to arrange a meeting.
            </Typography>
          </Grid>
          <Grid
            className={classes.section}
            alignContent='stretch'
            container
            alignItems='center'
          >
            <Typography variant='h6'>
              <Typography display='inline' variant='h5'>
                CONNECT
              </Typography>
              <span>&nbsp;</span> with other fellow members. Message sellers to
              inquire about their products and organize in-person meet ups.
              Follow them and receive push notifications when they upload new
              products.
            </Typography>
          </Grid>
          <Grid
            className={classes.section}
            alignContent='stretch'
            container
            alignItems='center'
          >
            <Typography variant='h6'>
              <Typography display='inline' variant='h5'>
                RATE
              </Typography>
              <span>&nbsp;</span> good members so that they can shine. Report
              scammers and bad actors to us so that we can remove them from our
              community. Hubble will be dedicated for legit resellers and
              will have a zero tolerance policy towards bad behavior. Verified
              resellers will have a verification sticker on their profile so
              that you can deal with them with assurance. (edited)
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

