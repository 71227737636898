import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  moreProductsContainer: {
    width: '100%',
    margin: '40px 0px',
    background: 'white',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 16px',
    },
  },
  container: {
    display: 'flex',
    background: 'white',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center'
  },
  viewMore: {
    cursor: 'pointer',
    margin: '10px 0',
    '&:hover': {
      color: 'grey',
    },
  },
}));