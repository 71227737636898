import React, { useState } from 'react';
import { useStyles } from './TopAppBar.style';
import { AppBar, Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Index } from 'react-instantsearch-dom';
import AutoComplete from './AutoComplete';
import logo from '../../assets/top_banner.png';

function TopBar() {
  const classes = useStyles();
  const history = useHistory({ forceRefresh: true });

  const [mobileSearchMode, setMobileSearchMode] = useState(false);

  return (
    <div>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolbar} >
          <div className={classes.logoContainer}>
            <img
              src={logo}
              alt='logo'
              className={classes.logo}
              onClick={() => history.push('/')}
            />
          </div>
          <AutoComplete
            mobileSearchMode={mobileSearchMode}
            setMobileSearchMode={setMobileSearchMode}
          />
          <Index indexName='Users_Shoes' />
          <Index indexName='Products_Shoes' />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default TopBar;

