import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ThemeProvider,
} from '@material-ui/core/styles';
import './styles/index.css';
import theme from './styles/theme';

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <App />
      </Router>
    </ThemeProvider>,
  document.getElementById('root')
);