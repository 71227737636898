import React from 'react';
import {
  AppBar,
  Tabs,
} from '@material-ui/core';

const ProfileTabs = (props) => {
  const { tab, handleChangeTab } = props;

  return (
    <AppBar position='static' elevation={0} style={{ backgroundColor: 'white' }} >
      <Tabs
        style={{ marginBottom: '8px', borderBottom: '1px solid #E0E0E0' }}
        value={tab}
        onChange={handleChangeTab}
        textColor='primary'
        aria-label='tabs between sneakers and feed'
        centered

        variant='fullWidth'
      >
      </Tabs>
    </AppBar>
  );
};

export default ProfileTabs;