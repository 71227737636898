import React, { useRef } from 'react';
import { useStyles } from './UserCarousel.style';
import { useHistory } from 'react-router-dom';
import {
  useTheme,
  Typography,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const UserCarousel = (props) => {
  const { title, data } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  /** Individual card element for carousel */
  const Card = ({ name, imageUrl}) => {
    return (
      <div className={classes.card}>
        <img
          src={imageUrl}
          alt='user'
          onClick={() => history.push(`/profile/${name}`)}
          className={classes.cardImage}
        />
        <Typography variant='body1' align='center'>
          {name}
        </Typography>
      </div>
    )
  }

  const scrollEl = useRef(null);

  /** scrollOffset: changes distance traveled */
  const scrollLeft = (scrollOffset) => {
    /** x: changes speed and distance */
    const x = 6;
    for (let i=0; i>scrollOffset; i--) {
      setTimeout(() => (scrollEl.current.scrollLeft -= x), Math.abs(i) * 2);
    }
  };

  /** scrollOffset: changes distance traveled */
  const scrollRight = (scrollOffset) => {
    /** x: changes speed and distance */
    const x = 8;
    for (let i=0; i<scrollOffset; i++) {
      setTimeout(() => (scrollEl.current.scrollLeft += x), i * 2);
    }
  };

  const handleLeftClick = () => {
    scrollLeft(-80);
  };
  const handleRightClick = () => {
    scrollRight(80);
  };

  return (
    <div className={classes.container} >
      <div
        style={{
          overflowX: 'hidden',
        }}
      >
        <Typography variant='h2' style={{fontWeight: 'bold', paddingBottom: '20px'}}>
          {title}
        </Typography>
        <div className={classes.itemsContainer} ref={scrollEl}>
          {data.map((item, i) => (
            // console.log(item.media.smallImageUrl)
            <Card
              itemId={item.id}
              name={item.nickname}
              key={i}
              imageUrl={item.profileImg}
            />
          ))}
        </div>
      </div>
      {useMediaQuery(theme.breakpoints.up('md')) &&
        <IconButton
          variant='contained'
          className={classes.leftArrowButton}
          onClick={handleLeftClick}
        >
          <ChevronLeftIcon className={classes.leftArrow} />
        </IconButton>
      }
      {useMediaQuery(theme.breakpoints.up('md')) &&
        <IconButton
          variant='contained'
          className={classes.rightArrowButton}
          onClick={handleRightClick}
        >
          <ChevronRightIcon className={classes.rightArrow} />
        </IconButton>
      }
    </div>
  );
};

export default UserCarousel;
