import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchBarContainer: {
    paddingLeft: 5,

    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      width: '100%',
      marginLeft: 10,
    },
  },
  hidden: {
    backgroundColor: '#F2F2F2',
    transition: 'all .2s ease',
  },
  notHidden: {
    backgroundColor: 'white',
    transition: 'all .2s ease',
  },
  inputContainer: {
    display: 'inline-flex',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      width: '100%',
    },
    height: '100%',
    alignItems: 'center',
    justifyContent:'left',
    backgroundColor: '#000000',
    border: '1px solid white',
    color: '#000000',
    borderRadius: 30,
    paddingRight: 8
  },
  input: {
    width: '100%',
    height: '100%',
    border: 1,
    color: '#ffffff',
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    fontFamily: 'CircularStd',
    borderRadius: 16,
    backgroundColor: '#000000',
  },
  inputIcon: {
    color: '#ffffff',
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: '#000000',
  },
}));