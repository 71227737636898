import React from 'react';
import { useStyles } from './BottomBar.style';
import { useTheme } from '@material-ui/core/styles';
import {
  Toolbar,
  Link,
  Typography,
  Grid,
  useMediaQuery,
  Button
} from '@material-ui/core';

import DownloadAppStoreButton from '../../assets/apple_logo/apple_store_download.svg';
import logo from '../../assets/top_banner.png';

import { useHistory } from 'react-router-dom';

const BottomBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const history = useHistory({ forceRefresh: true });

  return (
    <div className={classes.grow}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.bottomTextDiv}>
          <Typography
            className={matchesSmUp ? classes.bottomText : classes.bottomTextSm}
            align='center'
          >
            Buy and sell sneakers with Hubble
          </Typography>
        </div>
        <AppStoreButton classes={classes}/>
        <Grid container direction='row'>
          <Grid item xs={4} />
          <Grid item xs={4} className={classes.linksContainer}>
            <BottomBarLink goTo={() => history.push('/about')} text={'About'} />
            <BottomBarLink
              goTo={() => {
                const newWindow = window.open(
                  'https://app.termly.io/document/privacy-policy/3d2c7144-9d10-4ec1-9bcf-ea30c7792215',
                  '_blank',
                  'noopener, noreferrer'
                );
                if (newWindow) newWindow.opener = null;
              }}
              text={'Privacy'}
            />
            <BottomBarLink
              goTo={() => {
                const newWindow = window.open(
                  'mailto:hello@sneakerlist.co?Subject=HUBBLE[WEB] HELLO',
                  '_blank',
                  'noopener, noreferrer'
                );
                if (newWindow) newWindow.opener = null;
              }}
              text={'Contact'}
            />
            <BottomBarLink
              goTo={() => {
                const newWindow = window.open(
                  'https://app.termly.io/document/terms-of-use-for-website/74ea1170-96d7-4a64-9f66-798836146ea2',
                  '_blank',
                  'noopener, noreferrer'
                );
                if (newWindow) newWindow.opener = null;
              }}
              text={'Agreement'}
            />
          </Grid>
          <Grid item xs={2} />
        </Grid>

        <img
          className={matchesSmUp ? classes.logoImage : classes.logoImageSm}
          src={logo}
          alt={'Hubble logo'}
        />

        <div className={classes.copyRightContainer}>
          <Typography
            className={classes.copyrightText}
            align='center'
          >
            Copyright © 2021 Hubble Inc. All rights reserved.
          </Typography>
        </div>
      </Toolbar>
    </div>
  );
}

const AppStoreButton = ({classes}) => {
  const handleAppStoreButtonClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Button
      onClick={() => handleAppStoreButtonClick('https://apple.co/33B67Cd')}
      color='default'
      variant='text'
      className={classes.appButton}
    >
      <img
        style={{ border: '1px solid white', borderRadius: '10px' }}
        width='100%'
        height='45'
        alt='Apple Logo'
        src={DownloadAppStoreButton}
      />
    </Button>
  );
};

const BottomBarLink = ({ goTo, text }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Link
        onClick={() => {
          goTo();
        }}
        className={classes.linkContainer}
      >
        <Typography className={classes.linkText} align='center'>
          {text}
        </Typography>
      </Link>
    </Grid>
  );
};

export default BottomBar;