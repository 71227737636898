import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'black',
    justifyContent: 'center',
    paddingTop: 80
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50
  },
  centerSm: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: 'white',
    textTransform: 'none',
    height: 34,
    width: 135,
    padding: 0,
    '&:hover': { backgroundColor: '#E0E0E0' }
  },
  imageCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  mainImage: {
    height:'100%',
    width: '100%',
    objectFit: 'cover',
    marginBottom: 34,
    marginTop: 100,
  },
  mainImageSm: {
    height:'200%',
    width: '200%',
    objectFit: 'cover',
    marginBottom: 34,
    marginTop: 50,
  },
  mainText: {
    fontWeight: 'bold',
    fontSize: 42,
    color: '#ffffff',
    lineHeight: '50px',
  },
  mainTextSm: {
    fontWeight: 'bold',
    fontSize: 24,
    color: '#ffffff',
    paddingLeft : 10,
    paddingRight : 10,
  },
  bottomMargin: {
    marginBottom: 30
  },
  appButton: {
    size: 20,
    paddingRight : 20,
  }
}));