import React, { useState } from 'react';
import { useStyles } from './AutoComplete.style'
import { useTheme } from '@material-ui/core/styles';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const AutoComplete = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme2 = useTheme();
  const matchesMdUp = useMediaQuery(theme2.breakpoints.up('md'));
  const {
    hits,
    currentRefinement,
    refine,
    setMobileSearchMode,
  } = props;
  const [value, setValue] = useState(currentRefinement);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    refine(value);
  };

  const onSuggestionsClearRequested = () => {
    refine();
  };

  const getSuggestionValue = () => {
    return ''; //to clear input
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.price) {
      history.push(`/product/${suggestion.objectID}`, { product: suggestion });
    } else {
      history.push(`/profile/${suggestion.nickname}`);
    }
    setMobileSearchMode(false);
    window.location.reload(true); //force refresh
  };

  const renderSuggestion = (hit) => {
    if (hit.price) {
      return (
        <ListItem component='div' dense style={{ padding: 0 }}>
          <ListItemAvatar>
            <Avatar src={hit.images[0]}></Avatar>
          </ListItemAvatar>
          <ListItemText primary={hit.name} />
        </ListItem>
      );
    } else {
      return (
        <ListItem component='div' dense style={{ padding: 0 }}>
          <ListItemAvatar>
            <Avatar src={hit.profileImg}></Avatar>
          </ListItemAvatar>
          <ListItemText primary={hit.nickname} />
        </ListItem>
      );
    }
  };

  const renderSectionTitle = (section) => {
    if (section.index === 'Users_Shoes') {
      return 'Users';
    } else {
      return 'Products';
    }
  };

  const getSectionSuggestions = (section) => {
    return section.hits;
  };

  const inputProps = {
    placeholder: 'Try search Nike SB dunk...',
    onChange: onChange,
    value,
  };

  const renderInputComponent = (inputProps) => (
    <div className={classes.inputContainer}>
      <SearchIcon className={classes.inputIcon} />
      <input className={classes.input} {...inputProps} />
      {!matchesMdUp ? (
        <IconButton
          style={{
            color: '#ffffff',
            zIndex: 5,
            padding: 4,
          }}
          onClick={() => setMobileSearchMode(false)}
        >
          <ClearIcon />
        </IconButton>
      ) : null}
    </div>
  );

  const theme = {
    container: `react-autosuggest__container ${classes.autoCompleteContainer}`,
    containerOpen: 'react-autosuggest__container--open',
    input: `${classes.input}`,
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: `react-autosuggest__input--focused ${classes.notHidden}`,
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title',
  };

  return (
    <div className={classes.searchBarContainer}>
        <AutoSuggest
          theme={theme}
          suggestions={hits}
          multiSection={true}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          renderInputComponent={renderInputComponent}
        />
    </div>
  );
};

/**
 * AutoSuggest styling API
  {
    container:                'react-autosuggest__container',
    containerOpen:            'react-autosuggest__container--open',
    input:                    'react-autosuggest__input',
    inputOpen:                'react-autosuggest__input--open',
    inputFocused:             'react-autosuggest__input--focused',
    suggestionsContainer:     'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList:          'react-autosuggest__suggestions-list',
    suggestion:               'react-autosuggest__suggestion',
    suggestionFirst:          'react-autosuggest__suggestion--first',
    suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
    sectionContainer:         'react-autosuggest__section-container',
    sectionContainerFirst:    'react-autosuggest__section-container--first',
    sectionTitle:             'react-autosuggest__section-title'
  }
 */

export default connectAutoComplete(AutoComplete);
