import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {display: 'none'},
  },
  card: {
    // width: 176,
    [theme.breakpoints.up('md')]: {
      marginRight: 8,
      marginLeft: 8,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 4,
      marginLeft: 4,
    },

    display:'flex',
    flexDirection: 'column'
  },
  cardImage: {
    [theme.breakpoints.up('lg')]: {
      width: 176,
      height: 176,
    },
    [theme.breakpoints.down('md')]: {
      width: 144,
      height: 144,
    },
    aspectRatio: 1,
    objectFit:'cov',
    borderRadius: 8,
    marginBottom: 8,
  },
  leftArrowButton: {
    position: 'absolute',
    left: '-24px',
    [theme.breakpoints.up('lg')]: {
      top: '104px',
    },
    [theme.breakpoints.down('md')]: {
      top: '88px',
    },
    height: 48,
    width: 48,
    zIndex: 1,
    boxShadow: '0px 4px 4px 0px rgb(180, 180, 180, 0.25)',
    color: 'black',
    backgroundColor: 'white',
    '&:hover' : {
      backgroundColor: '#E0E0E0'
    },
  },
  leftArrow: {
    height: 40,
    width: 40,
  },
  rightArrowButton: {
    position: 'absolute',
    [theme.breakpoints.up('lg')]: {
      top: '104px',
    },
    [theme.breakpoints.down('md')]: {
      top: '88px',
    },
    right: '-24px',
    height: 48,
    width: 48,
    zIndex: 1,
    boxShadow: '0px 4px 4px 0px rgb(180, 180, 180, 0.25)',
    color: 'black',
    backgroundColor: 'white',
    '&:hover' : {
      backgroundColor: '#E0E0E0'
    },
  },
  rightArrow: {
    height: 40,
    width: 40,
  },
}));