import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {display: 'none'},
  },
  card: {
    // width: 176,
    [theme.breakpoints.up('lg')]: {
      marginRight: 22,
      marginLeft: 22,
      width: 148,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 20,
      marginLeft: 20,
      width: 120,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 12,
      marginLeft: 12,
    },
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardImage: {
    [theme.breakpoints.up('lg')]: {
      width: 148,
      height: 148,
    },
    [theme.breakpoints.down('md')]: {
      width: 120,
      height: 120,
    },
    aspectRatio: 1,
    objectFit:'fill',
    borderRadius: '50%',
    marginBottom: 8,
    cursor:'pointer',
  },
  leftArrowButton: {
    position: 'absolute',
    [theme.breakpoints.up('lg')]: {
      top: '90px',
    },
    [theme.breakpoints.down('md')]: {
      top: '76px',
    },
    left: '-24px',
    height: 48,
    width: 48,
    zIndex: 1,
    boxShadow: '0px 4px 4px 0px rgb(180, 180, 180, 0.25)',
    color: 'black',
    backgroundColor: 'white',
    '&:hover' : {
      backgroundColor: '#E0E0E0'
    },
  },
  leftArrow: {
    height: 40,
    width: 40,
  },
  rightArrowButton: {
    position: 'absolute',
    [theme.breakpoints.up('lg')]: {
      top: '90px',
    },
    [theme.breakpoints.down('md')]: {
      top: '76px',
    },
    right: '-24px',
    height: 48,
    width: 48,
    zIndex: 1,
    boxShadow: '0px 4px 4px 0px rgb(180, 180, 180, 0.25)',
    color: 'black',
    backgroundColor: 'white',
    '&:hover' : {
      backgroundColor: '#E0E0E0'
    },
  },
  rightArrow: {
    height: 40,
    width: 40,
  },
}));