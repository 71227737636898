import React from 'react';
import { useStyles } from './DownloadSection.style';
import { useTheme } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import DownloadAppStoreButton from '../../../assets/apple_logo/apple_store_download.svg';
import hero_image from '../../../assets/hero_image.png';

const DownloadSection = () => {
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={matchesSmUp ? classes.center : classes.centerSm}>
        <Typography display='block' className={matchesSmUp ? classes.mainText : classes.mainTextSm}>
        Peer-to-peer resale marketplace platform with authentication
        </Typography>
      </div>
      <div className={classes.bottomMargin}></div>
      <div className={classes.center}>
        <AppStoreButton classes={classes}/>
      </div>
      <div className={classes.imageCenter}>
       <img
          className={matchesSmUp ? classes.mainImage : classes.mainImageSm}
          src={hero_image}
          alt={'Hubble logo'}
        />
      </div>
    </div>
  )
};

const AppStoreButton = ({classes}) => {
  const handleAppStoreButtonClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Button
      onClick={() => handleAppStoreButtonClick('https://apple.co/33B67Cd')}
      color='default'
      variant='text'
      className={classes.appButton}
    >
      <img
        style={{ border: '1px solid white', borderRadius: '10px' }}
        width='100%'
        height='40'
        alt='Apple Logo'
        src={DownloadAppStoreButton}
      />
    </Button>
  );
};

export default DownloadSection;
