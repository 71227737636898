import React from 'react';
import { useStyles } from './PortfolioSection.style';
import { useTheme } from '@material-ui/core/styles';
import {
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import portfolio_section_image from '../../../assets/portfolio_section.png';

const PortfolioSection = () => {
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={matchesSmUp ? classes.row : classes.rowSm}>
        <div className={matchesSmUp ? classes.column1 : classes.column1Sm}>
          <div className={classes.imageCenter}>
            <img
              className={matchesSmUp ? classes.mainImage : classes.mainImageSm}
              src={portfolio_section_image}
              alt={'portfolio management for resellable sneakers'}
            />
          </div>
        </div>
        <div className={matchesSmUp ? classes.column2 : classes.column2Sm}>
          <div className={matchesSmUp ? classes.center : classes.centerSm}>
            <Typography className={matchesSmUp ? classes.mainText : classes.mainTextSm}>
            Manage your inventory with ease
            </Typography>
          </div>
          <div className={matchesSmUp ? classes.center : classes.centerSm}>
            <Typography className={matchesSmUp ? classes.subText : classes.subTextSm}>
            Use our inventory management tool to track your portfolio prices as well as gains and losses
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PortfolioSection;