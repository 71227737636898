import React from 'react';
import { useStyles } from './AuthenticationSection.style';
import { useTheme } from '@material-ui/core/styles';
import {
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import authentication_section_image from '../../../assets/authentication_section.png';

const AuthenticationSection = () => {
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={matchesSmUp ? classes.column : classes.columnSm}>
          <div className={matchesSmUp ? classes.center : classes.centerSm}>
            <Typography className={matchesSmUp ? classes.mainText : classes.mainTextSm}>
            Buy and sell directly with others
            </Typography>
          </div>
          <div className={matchesSmUp ? classes.center : classes.centerSm}>
            <Typography className={matchesSmUp ? classes.subText : classes.subTextSm}>
            Manage your resale store and build a loyal customer base. All products are authenticated and guaranteed by us
            </Typography>
          </div>
        </div>
        <div className={matchesSmUp ? classes.column : classes.columnSm}>
          <div className={classes.imageCenter}>
            <img
              className={matchesSmUp ? classes.mainImage : classes.mainImageSm}
              src={authentication_section_image}
              alt={'authentication for resellable sneakers'}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default AuthenticationSection;