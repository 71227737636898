import React, { useEffect, useState } from 'react';
import { useStyles } from './Home.style';
import { CircularProgress } from '@material-ui/core';

import ProductCarousel from '../../components/Home/ProductCarousel';
import UserCarousel from '../../components/Home/UserCarousel';
import { getRecentProducts } from '../../utils/HomePage/getRecentProducts';
import getFeaturedUsers from '../../utils/HomePage/getFeaturedUsers';
import DownloadSection from '../../components/Home/DownloadSection';
import PriceSection from '../../components/Home/PriceSection';
import PortfolioSection from '../../components/Home/PortfolioSection';
import AuthenticationSection from '../../components/Home/AuthenticationSection';

const Home = () =>  {
  const classes = useStyles();
  const [recentProducts, setRecentProducts] = useState([]);
  const [featuredUsers, setFeaturedUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllData = async () => {
    const usersData = await getFeaturedUsers(20);
    setFeaturedUsers(usersData);

    const recentData = await getRecentProducts(20);
    setRecentProducts(recentData.productsDocs);

    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllData();
  }, []);

  return (
    <React.Fragment>
      <DownloadSection/>
      <AuthenticationSection/>
      <div className={classes.root}>
      { loading ?
        <CircularProgress/> :
        <div className={classes.container}>
          <ProductCarousel
            title={'Recently posted by seller'}
            data={recentProducts}
          />
          <UserCarousel
            title={'Featured sellers'}
            data={featuredUsers}
          />
        </div>
      }
      </div>
      <PriceSection/>
      <PortfolioSection/>
    </React.Fragment>
  );
}

export default Home;

